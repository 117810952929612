export const compliments = [
  "Damn shawty you fine",
  "Lovely is such an understatement for the perfection that is you.",
  "Every time I look at your eyes, it brightens up my day.",
  "You Look Great Today. (Yes Even though I can't see you right now it's just facts",
  "You’re Sexy.",
  "You’re perfect just the way you are. Remember that there’s no one else in the world like you.",
  "You deserve the world. Never settle!",
  "I find myself lost in your eyes every time; they’re so beautiful it’s hard to get out of them.",
  "It's kinda annoying how AMAZING you are",
  "Thanks for being you, you make me comfortable to be me",
  "I am so grateful that we met",
  "Thanks for swiping right (and messaging me first)",
  "Saying you a baddie isn't enough, you might just be too bad",
  "Your laugh is like music to my ears",
  "Damn, your smile. It is so pretty.",
  "Your face. I like it.",
  "I am too lucky to be able to call you mine",
  "Your laugh is the cutest of all.",
  "Know you have so much value, beyond your beauty",
  "You're a dream girl, specifcally my dream girl",
  "I'm so glad I get to keep you",
  "You should know, I am thinkng about you right now, I know this becuase I am always doing that",
  "Just one look at you and my heart skips a beat.",
  "Actually how can it be possible you always looks so damn great",
  "I can't seem to get you out of my head, no matter how hard I try.",
  "Your lips always look kissable.",
  "You are stunning",
  "I feel so much happier around you, I promise to make sure Ill do my best to repay you for that",
  "I GOT YOU, Need something, or me, I am there in a heartbeat never forget that, you're mine, I'd move mountains for you",
  "If you see this one call me Right Now and get a custom compliment! Plus I always want to hear your voice",
];
