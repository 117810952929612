import React from "react";

const Popup = ({ text, onClose }) => (
  <div className="popup-box">
    <div className="box">
      <span className="close-icon" onClick={onClose}>
        x
      </span>
      {text}
    </div>
  </div>
);

export default Popup;
