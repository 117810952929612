import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import Popup from "./popup";
const MyMarker = ({ text, tooltip, onClick }) => (
  <div className="circle">
    <span
      className="circleText"
      title={tooltip}
      onClick={() => onClick(tooltip)}
    >
      {text}
    </span>
  </div>
);

const cities = [
  {
    title: "C",
    lat: 25.776241,
    lng: -80.186333,
    desc: "Our First Concert and Get Away",
  },
  {
    title: "K",
    lat: 28.42053,
    lng: -81.581213,
    desc: "Techincally your place, but alot of first happen here, first kiss first makeout also where you most likely message me on tinder, 9hr call, and probably more I a missing",
  },
  {
    title: "P",
    lat: 28.42053,
    lng: -81.581213,
    desc: "The place I knew I wanted you to be mine, yes this is the Pinocchio Village Hause",
  },
  {
    title: "1st",
    lat: 27.961066,
    lng: -82.463546,
    desc: "Our First Date, Yest this is M.Bird",
  },
  {
    title: "MC",
    lat: 27.943966,
    lng: -82.336493,
    desc: "Our McDonalds, Also where I ask you to be mine",
  },
  {
    title: "S",
    lat: 28.084049,
    lng: -81.302687,
    desc: "First month anniversary, and visited sloth",
  },
];
const MapChart = () => {
  const defaultProps = {
    center: {
      lat: 27.994402,
      lng: -81.760254,
    },
    zoom: 7,
  };

  const [tooltip, setTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const toggleTooltip = (text) => {
    setTooltip(!tooltip);
    setTooltipText(text);
    console.log("HER", tooltip);
    console.log("HER", text);
  };
  const handleClose = () => {
    setTooltip(false);
    setTooltipText("");
  };
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {tooltip && <Popup text={tooltipText} onClose={handleClose} />}
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyCAO_we2klxsr86Q2c6s3qLRqTxLKmGj0s",
          language: "en",
          region: "US",
          version: "3.30",
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {cities.map((city, index) => (
          <MyMarker
            key={`${index}-${city.lat}`}
            lat={city.lat}
            lng={city.lng}
            text={city.title}
            tooltip={city.desc}
            show={tooltip}
            onClick={toggleTooltip}
          ></MyMarker>
        ))}
      </GoogleMapReact>
    </div>
  );
};
export default MapChart;
