import React, { useState } from "react";
import { compliments } from "./data";
const getRandomArbitrary = () => {
  const min = Math.ceil(0);
  const max = Math.floor(20);
  const index = Math.floor(Math.random() * (max - min + 1)) + min;
  return compliments[index];
};
const Generator = () => {
  const [text, setText] = useState("");
  const genCompliment = () => {
    setText(getRandomArbitrary);
  };

  return (
    <div>
      <div className="card">
        <h2>Get Your Random Cute Text</h2>
        <h4>{text}</h4>
        <button className="button" onClick={genCompliment}>
          <span>Get Cute Text</span>
        </button>
      </div>
    </div>
  );
};

export default Generator;
