import logo from "./logo.svg";
import "./App.css";
import MapChart from "./map";
import Generator from "./generator";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Generator />
      </header>
      <MapChart />
    </div>
  );
}

export default App;
